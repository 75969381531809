<template>
  <div class="nav">
    <div class="card-header">
      <vs-col class="col-page">
        <div class="header-init">
          <a style="color: black; margin-left: 15px" href="/home">
            <font-awesome-icon :icon="['fas', 'home']" />
            Início
          </a>
          <a style="color: black; margin: 0 15px" href="/perfil">
            <font-awesome-icon :icon="['fas', 'user-cog']" />
            Perfil
          </a>
          <a @click="logout()">
            <font-awesome-icon :icon="['fas', 'sign-out-alt']" />
            Sair
          </a>
        </div>
        <div class="header-mobile">
          <a style="color: black; margin-left: 15px" href="/home">
            <font-awesome-icon :icon="['fas', 'home']" />
          </a>
          <a style="color: black; margin: 0 10px" href="/perfil">
            <font-awesome-icon :icon="['fas', 'user-cog']" />
          </a>
          <a @click="logout()">
            <font-awesome-icon :icon="['fas', 'sign-out-alt']" />
          </a>
        </div>
      </vs-col>
      <vs-col class="col-data">
        <div v-if="this.nome">
          <a
            class="endereco"
            href="https://qi.edu.br/unidades/"
            target="_blank"
          >
            <font-awesome-icon size="lg" :icon="['fas', 'map-marker-alt']" />
          </a>
          <a class="sac" @click="openSac()">
            <font-awesome-icon size="lg" :icon="['fas', 'headset']" />
          </a>
          <p style="font-weight: bold; font-size: 1.15em" class="nome">
            Olá, {{ this.nome }} <br>
            <span style="font-weight: normal; font-size: 0.75em; color: gray">
              <font-awesome-icon style="margin-right: 1.5px" :icon="['fas', 'user-circle']" />
              {{ this.user }}
            </span>
          </p>
          <a href="/perfil">
            <vs-avatar
              v-if="this.image != null"
              size="45px"
              :src="`data:image/png;base64,${this.image}`"
            />
            <vs-avatar v-else size="45px"></vs-avatar>
          </a>
        </div>
        <div v-else class="spinner-container">
          <div class="spinner"></div>
        </div>
      </vs-col>
    </div>

    <vs-popup title="Fale Conosco" :active.sync="popupSac">
      <vs-card outlined>
        <vs-table class="table_intranet" hoverFlat :data="dataQI">
          <template slot-scope="{ data }">
            <vs-tr v-for="(i, index) in data" :key="index.sac">
              <vs-td>
                <img
                  v-if="data[index].contact == 'WhatsApp'"
                  class="whatsapp"
                  src="../assets/whatsapp.png"
                  alt="WhatsApp"
                />
                <font-awesome-icon
                  v-else
                  style="margin-right: 3.5px"
                  color="black"
                  :icon="data[index].icon"
                />
                {{ data[index].contact }}:
              </vs-td>

              <vs-td>
                <a
                  :href="data[index].href"
                  target="_blank"
                  style="color: black; font-weight: bold"
                  >{{ data[index].text }}</a
                >
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-card>
    </vs-popup>
  </div>
</template>

<script>
import SimpleCrypto from "simple-crypto-js";
import Person from "../services/person";
import {
  logoffUserAndEraseData,
  getLoginDataFromStorage,
  isLogged as isLoggedHelper,
  firstName,
  nameFormat,
} from "../helpers/storage";

export default {
  name: "Header",
  data: () => ({
    popupSac: false,
    dataQI: [
      {
        contact: "Telefone",
        text: "0800 000 0301",
        href: "tel:0800-0000-301",
        icon: "phone-volume",
      },
      {
        contact: "WhatsApp",
        text: "(51) 995413830",
        href: "https://api.whatsapp.com/send?phone=5551995413830",
        icon: "chat",
      },
      {
        contact: "Web",
        text: "http://qi.edu.br/ouvidoria",
        href: "http://qi.edu.br/ouvidoria",
        icon: "wifi",
      },
    ],
    nome: null,
    ra: null,
    user: null,
    image: null,
  }),

  mounted() {
    const { ...user } = getLoginDataFromStorage();
    this.user = user.coduser;

    new Person()
      .getPersonalData()
      .then(({ data }) => {
        const secretKey = "QI-System-@@";
        const simpleCrypto = new SimpleCrypto(secretKey);
        this.personalData = [simpleCrypto.decrypt(data)];

        this.ra = this.personalData[0].RA;
        this.image = this.personalData[0].FOTO;
        this.nome = firstName(nameFormat(this.personalData[0].NOME));
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      });
  },

  methods: {
    openSac() {
      this.popupSac = true;
    },

    logout() {
      logoffUserAndEraseData();
      this.isLogged = isLoggedHelper();
      this.steps = [];
      this.$router.push("/");
    },
  },
};
</script>

<style>
tr:nth-child(even) {
  background-color: #d4d4d4;
}

.spinner-container {
  display: flex;
  justify-content: flex-end;
  margin: 15px 0 15px -10px;
  width: 100%;
}

.spinner {
  border: 2.5px solid rgba(0, 0, 0, 0.1);
  border-left-color: #9b0e1c;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.card-header {
  width: 77.65%;
  padding: 3.5px;
  z-index: 999;
  position: fixed;
  background-color: white;
  border: 1.5px solid #b5b5b59a;
  border-radius: 20px;
  box-shadow: 0.5px 5px 5px #b5b5b59a;
}

.col-page {
  width: 40% !important;
}

.col-page > div {
  display: flex;
  margin-top: 18.5px;
}

.header-mobile {
  display: none !important;
}

.col-data {
  width: 60% !important;
}

.col-data > div {
  display: flex;
  justify-content: flex-end;
}

.endereco {
  margin-top: 14.85px;
  margin-right: 11.5px;
}

.sac {
  color: black;
  margin-top: 15px;
  margin-right: 11.5px;
}

.nome {
  margin-top: 15px;
  margin-right: 10px;
}

.whatsapp {
  width: 11.5px;
  margin-right: 2.5px;
}

.itens {
  margin: 1.5px 0 0 7.5px;
}

@media (max-width: 1385px) {
  .card-header {
    width: 75.5%;
  }
}

@media (max-width: 1255px) {
  .card-header {
    width: 71.95%;
  }
}

@media (max-width: 1135px) {
  .card-header {
    width: 69.15%;
  }
}

@media (max-width: 1015px) {
  .card-header {
    width: 66.85%;
  }
}

@media (max-width: 935px) {
  .card-header {
    width: 62.75%;
  }
}

@media (max-width: 890px) {
  .col-page,
  .col-data {
    font-size: 0.915em;
  }

  .col-page > div {
    margin-top: 20px;
  }

  .endereco {
    margin-top: 16.5px;
  }

  .sac {
    margin-top: 16.5px;
  }

  .nome {
    margin-top: 16.5px;
  }
}

@media (max-width: 845px) {
  .card-header {
    width: 92.85%;
  }
}

@media (max-width: 570px) {
  .col-page,
  .col-data {
    font-size: 0.785em;
  }

  .endereco {
    margin-top: 17.5px;
  }

  .sac {
    margin-top: 17.5px;
  }

  .nome {
    margin-top: 17.5px;
  }
}

@media (max-width: 500px) {
  .card-header {
    width: 91.85%;
  }

  .header-init {
    display: none !important;
  }

  .header-mobile {
    display: block !important;
  }

  .col-page,
  .col-data {
    font-size: 0.95em;
  }

  .col-page {
    width: 30% !important;
  }

  .col-data {
    width: 70% !important;
  }
}

@media (max-width: 430px) {
  .col-page,
  .col-data {
    font-size: 0.85em;
  }
}

@media (max-width: 380px) {
  .col-page,
  .col-data {
    font-size: 0.785em;
  }
}

@media (max-width: 355px) {
  .col-page,
  .col-data {
    font-size: 0.685em;
  }
}
</style>