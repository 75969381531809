<template>
  <div>
    <vs-divider />
    <div class="card-sup">
      <vs-col>
        <div class="card-info">
          <h3>
            <font-awesome-icon
              style="margin-right: 3.5px"
              :icon="['fas', 'ban']"
            />
            Cancelamento
          </h3>
          <vs-divider />
          <ValidationObserver ref="observer">
            <div class="con-select-example">
              <vs-row class="pd-t">
                <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                  <fieldset>
                    <legend>Email do Resp. Financeiro</legend>
                    <vs-input
                      class="selectExampleDouble"
                      :placeholder="emailFinancialResponsible"
                      disabled
                    />
                  </fieldset>
                </vs-col>

                <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                  <fieldset>
                    <legend>Telefone</legend>
                    <vs-input
                      class="selectExampleDouble"
                      :placeholder="telephone"
                      disabled
                    />
                  </fieldset>
                </vs-col>
              </vs-row>

              <vs-row>
                <div class="error-info">
                  <b>
                    <font-awesome-icon
                      style="color: red; margin-right: 3.5px"
                      :icon="['fas', 'exclamation-triangle']"
                    />
                    Para efetuar a solicitação de cancelamento, dirija-se à
                    secretaria da sua escola.
                  </b>

                  <p
                    v-if="this.poloAddress[0]"
                    style="margin-top: 13.5px; margin-left: 2.5px"
                  >
                    <font-awesome-icon
                      style="margin-right: 2.5px"
                      :icon="['fas', 'map-marker-alt']"
                    />
                    {{ this.poloAddress[0].RUA }},
                    {{ this.poloAddress[0].NUMERO }} ({{
                      this.poloAddress[0].BAIRRO
                    }}) - {{ this.poloAddress[0].CIDADE }}/{{
                      this.poloAddress[0].ESTADO
                    }}
                  </p>
                  <p v-if="this.course.CODMODALIDADECURSO == '4'" style="margin-top: 5px; margin-left: 3.5px">
                    ou ligue:
                    <a href="tel:5130140003">
                      <font-awesome-icon :icon="['fas', 'phone-volume']" />
                      51 3014.0003
                    </a>
                  </p>
                </div>
              </vs-row>

              <!--<vs-row>
                <vs-col>
                  <ValidationProvider
                    name="Observação"
                    rules="required|max: 500"
                  >
                    <vs-textarea
                      rows="11"
                      label="Observação *"
                      class="textArea"
                      height="15em"
                      counter="500"
                      v-model="solicitation"
                    />
                  </ValidationProvider>
                </vs-col>
              </vs-row>

              <vs-divider />

              <vs-row>
                <legend>Responda o questionário:</legend>
              </vs-row>

              <vs-row>
                <vs-col>
                  <ValidationProvider
                    name="de motivação"
                    rules="required|max: 500"
                  >
                    <vs-textarea
                      rows="11"
                      label="O QUE MOTIVOU VOCÊ A ESTUDAR NA QI? *"
                      class="textArea"
                      height="15em"
                      counter="500"
                      v-model="motivation"
                    />
                  </ValidationProvider>
                </vs-col>
              </vs-row>

              <vs-row>
                <vs-col>
                  <ValidationProvider
                    name="de escolha"
                    rules="required|max: 500"
                  >
                    <vs-textarea
                      rows="11"
                      label="O QUE LEVOU VOCÊ A ESCOLHER ESSE CURSO? *"
                      class="textArea"
                      height="15em"
                      counter="500"
                      v-model="choice"
                    />
                  </ValidationProvider>
                </vs-col>
              </vs-row>

              <vs-row>
                <vs-col>
                  <ValidationProvider
                    name="do que você gosta"
                    rules="required|max: 500"
                  >
                    <vs-textarea
                      rows="11"
                      label="O QUE VOCÊ MAIS GOSTA EM NOSSAS ESCOLAS/FACULDADES? *"
                      class="textArea"
                      height="15em"
                      counter="500"
                      v-model="appreciate"
                    />
                  </ValidationProvider>
                </vs-col>
              </vs-row>

              <vs-row>
                <vs-col>
                  <ValidationProvider
                    name="de continuação"
                    rules="required|max: 500"
                  >
                    <vs-textarea
                      rows="11"
                      label="O QUE MOTIVARIA VOCÊ A CONTINUAR COM A QI? *"
                      class="textArea"
                      height="15em"
                      counter="500"
                      v-model="continuation"
                    />
                  </ValidationProvider>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col style="text-align: center">
                  <vs-button
                    color="danger"
                    type="gradient"
                    class="button-protocol"
                    @click="resetForm()"
                    :disabled="
                      solicitation == null &&
                      motivation == null &&
                      choice == null &&
                      appreciate == null &&
                      continuation == null
                    "
                  >
                    <font-awesome-icon style="margin-right: 3.5px;" :icon="['fas', 'times-circle']" />
                    Cancelar
                  </vs-button>

                  <vs-button
                    class="button-protocol"
                    color="success"
                    type="gradient"
                    @click="createAttendance()"
                    :disabled="invalid || idAttendanceDuplicate"
                  >
                    <font-awesome-icon style="margin-right: 3.5px;" :icon="['fas', 'check-circle']" />
                    Gerar
                  </vs-button>
                </vs-col>
              </vs-row>-->
            </div>
          </ValidationObserver>
        </div>
      </vs-col>
    </div>
  </div>
</template>

<script>
import SimpleCrypto from "simple-crypto-js";
import Person from "../../../services/person";
import Management from "../../../services/management";
import Student from "../../../services/students";
import ProtocolModel from "../../../models/menus/protocols/protocolTypes";
import Attendance from "../../../services/attendance";

export default {
  name: "Cancelamento",
  props: {
    course: Object,
  },
  data: () => ({
    idAttendance: null,
    errorIdAttendance: null,
    idAttendanceDuplicate: null,
    personalData: [],
    poloAddress: [],
    emailFinancialResponsible: null,
    telephone: null,
    solicitation: null,
    motivation: null,
    choice: null,
    appreciate: null,
    continuation: null,
  }),

  mounted() {
    this.$vs.loading({ text: "Carregando Dados Acadêmicos 🪪" });
    new Person()
      .getPersonalData()
      .then(({ data }) => {
        const secretKey = "QI-System-@@";
        const simpleCrypto = new SimpleCrypto(secretKey);
        this.personalData = [simpleCrypto.decrypt(data)];
        
        this.nome = this.personalData[0].NOME;
        this.ra = this.personalData[0].RA;
        this.email =
          this.personalData[0].EMAIL == null ? "-" : this.personalData[0].EMAIL;
        this.emailFinancialResponsible =
          this.personalData[0].EMAILRESPONSAVELFINANCEIRO == null
            ? "-"
            : this.personalData[0].EMAILRESPONSAVELFINANCEIRO;
        this.telephone =
          this.personalData[0].TELEFONE1 == null
            ? "-"
            : `(${this.personalData[0].TELEFONE1.slice(
                0,
                2
              )}) ${this.personalData[0].TELEFONE1.slice(2, 12)}`;
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });

    new Management()
      .getProtocolInProgress()
      .then(({ data }) => {
        this.protocolInProgress = (Array.isArray(data) ? data : [data]).filter(
          (a) =>
            a.ASSUNTOOC == "CANCELAMENTO - PORTAL DO ALUNO" &&
            a.CURSO == `(${this.course.CODCURSO}) ${this.course.CURSO}`
        );

        if (this.protocolInProgress.length != 0) {
          this.idAttendanceDuplicate =
            this.protocolInProgress[0].CODATENDIMENTO;
          this.$vs.loading.close();
        }
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      });

    new Student()
      .getPoloAddress(this.course.CODCOLIGADA, this.course.POLO)
      .then(({ data }) => {
        this.poloAddress = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },

  methods: {
    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      this.idAttendanceDuplicate = null;

      const studentName = this.nome;
      const studentRA = this.ra;

      /* INFORMAÇÕES EXTERNAS */
      const courseCancellation = this.course.CURSO;

      const payload = new ProtocolModel().getDataByAttendanceId("P2");
      payload.data.locationId = this.course.POLO;
      payload.data.ra = studentRA;
      payload.data.nameCourse = `(${this.course.CODCURSO}) ${this.course.CURSO}`;
      payload.data.requestText = `RA: ${studentRA}\nALUNO: ${studentName}\nE-MAIL DO ALUNO: ${this.email}\nE-MAIL DO RESPONSÁVEL FINANCEIRO: ${this.emailFinancialResponsible}\nTELEFONE: ${this.telephone}\nCURSO: ${courseCancellation}\nSOLICITAÇÃO: ${this.solicitation}\n\n---------- QUESTIONÁRIO ----------\nO QUE MOTIVOU VOCÊ A ESTUDAR NA QI? ${this.motivation}\nO QUE LEVOU VOCÊ A ESCOLHER ESSE CURSO? ${this.choice}\nO QUE VOCÊ MAIS GOSTA EM NOSSAS ESCOLAS/FACULDADES? ${this.appreciate}\nO QUE MOTIVARIA VOCÊ A CONTINUAR COM A QI? ${this.continuation}`;

      this.$vs.loading({ text: "Gerando Atendimento 🏷️" });
      new Management()
        .getProtocolInProgress()
        .then(({ data }) => {
          this.protocolInProgress = (
            Array.isArray(data) ? data : [data]
          ).filter(
            (a) =>
              a.ASSUNTOOC == payload.data.subject &&
              a.CURSO == payload.data.nameCourse
          );

          if (this.protocolInProgress.length != 0) {
            this.idAttendanceDuplicate =
              this.protocolInProgress[0].CODATENDIMENTO;
            this.$vs.loading.close();
          } else {
            new Attendance()
              .createAttendance(payload.data)
              .then(({ data }) => {
                this.idAttendance = data;
                this.$vs.loading.close();
              })
              .catch(({ response }) => {
                if (response && response.data) {
                  this.errorIdAttendance = response.data;
                  console.log(response);
                }
                this.$vs.loading.close();
              });
          }
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data;
          }
        });

      this.resetForm();
    },

    resetForm() {
      this.solicitation = null;
      this.motivation = null;
      this.choice = null;
      this.appreciate = null;
      this.continuation = null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
  },
};
</script>

<style scoped>
.card-sup {
  margin: 20px 8.5px 8.5px 8.5px;
}

legend {
  font-size: 1.05em;
}
</style>