/*

1 - Técnico
2 - Livre
3 - Graduação
4 - Idiomas
5 - Pós-Graduação
6 - Extensão

*/

export default class {
    constructor() {
        this.instance = 
            [     
                {
                    typeAttendanceId: "P1", /* PROTOCOLO / CERTIFICADO */
                    title: 'Certificado',
                    modality: [2,5],
                    concluded: ["S","N"],
                    data: {                          
                        respAttendantId: "4330", // NÃO RESPEITA - VIA RM
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00008",                       
                        subject: "CERTIFICADO - PORTAL DO ALUNO",
                        nameCourse: "",
                        accessLevel: null,
                        typeAttendanceId: "165",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "4328",               
                    }
                },

                {
                    typeAttendanceId: "P2", /* PROTOCOLO / CANCELAMENTO */
                    title: 'Cancelamento',
                    modality: [1,2,3,4,5],
                    concluded: ["N"],
                    data: {                          
                        respAttendantId: "5100",
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00066",                       
                        subject: "CANCELAMENTO - PORTAL DO ALUNO",
                        nameCourse: "",
                        accessLevel: null,
                        typeAttendanceId: "195",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "4328",
                    }
                },

                {
                    typeAttendanceId: "P3", /* PROTOCOLO / DIPLOMA */
                    title: 'Diploma',
                    modality: [1],
                    concluded: ["S","N"],
                    data: {                          
                        respAttendantId: "4330", // NÃO RESPEITA - VIA RM
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00005",                       
                        subject: "DIPLOMA - PORTAL DO ALUNO",
                        nameCourse: "",
                        attachmentRGFrente: "",
                        fileNameRGFrente: "",
                        attachmentRGVerso: "",
                        fileNameRGVerso: "",
                        attachmentCNH: "",
                        fileNameCNH: "",
                        attachmentCertNasc: "",
                        fileNameCertNasc: "",
                        attachmentCPF: "",
                        fileNameCPF: "",
                        attachmentCompResid: "",
                        fileNameCompResid: "",
                        attachmentHistoricEnsMed: "",
                        fileNameHistoricEnsMed: "",
                        attachmentCertificado: "",
                        fileNameCertificado: "",
                        accessLevel: null,
                        typeAttendanceId: "237",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "4328",     
                    }
                },

                {
                    typeAttendanceId: "P3-1", /* PROTOCOLO / HISTÓRICO */
                    title: 'Histórico',
                    modality: [1,2,3,4,5],
                    concluded: ["S","N"],
                    data: {                          
                        respAttendantId: "", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00009",  
                        subject: "HISTÓRICO - PORTAL DO ALUNO",
                        nameCourse: "",
                        accessLevel: null,
                        typeAttendanceId: "236",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "4328",       
                    }
                },

                {
                    typeAttendanceId: "P4", /* PROTOCOLO / FORMATURA GRADUAÇÃO EAD */
                    title: 'Formatura',
                    modality: [3,5],
                    concluded: ["S","N"],
                    data: {                          
                        respAttendantId: "4288", // NÃO RESPEITA - VIA RM
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00018",                       
                        subject: "FORMATURA GRADUAÇÃO/PÓS - PORTAL DO ALUNO",
                        nameCourse: "",
                        attachmentRGFrente: "",
                        fileNameRGFrente: "",
                        attachmentRGVerso: "",
                        fileNameRGVerso: "",
                        attachmentCertNasc: "",
                        fileNameCertNasc: "",
                        attachmentCPF: "",
                        fileNameCPF: "",
                        attachmentHistoricEnsMed: "",
                        fileNameHistoricEnsMed: "",
                        attachmentCertificado: "",
                        fileNameCertificado: "",
                        accessLevel: null,
                        typeAttendanceId: "272",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "4328",
                    }
                },

                /*{
                    typeAttendanceId: "P5", PROTOCOLO / NOTA FISCAL
                    title: 'Nota Fiscal',
                    modality: [1,2,3,4,5],
                    concluded: ["S","N"],
                    data: {                          
                        respAttendantId: "4701", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00062",                       
                        subject: "NOTA FISCAL - PORTAL DO ALUNO",
                        nameCourse: "",
                        accessLevel: null,
                        typeAttendanceId: "164",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "4328",                  
                    }
                },*/

                {
                    typeAttendanceId: "P6", /* PROTOCOLO / RETORNO DE TRANCAMENTO */
                    title: 'Retorno de Trancamento',
                    modality: [2,3],
                    concluded: ["N"],
                    data: {                          
                        respAttendantId: "", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00045",                       
                        subject: "RETORNO DE TRANCAMENTO - PORTAL DO ALUNO",
                        nameCourse: "",
                        accessLevel: null,
                        typeAttendanceId: "168",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "4328",
                    }
                },

                {
                    typeAttendanceId: "P7", /* PROTOCOLO / TERMO DE ESTÁGIO */
                    title: 'Termo de Estágio',
                    modality: [1,2,3,4,5],
                    concluded: ["S","N"],
                    data: {                          
                        respAttendantId: "5782",
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00065",                       
                        subject: "TERMO DE ESTÁGIO - PORTAL DO ALUNO",
                        nameCourse: "",
                        companyName: "",
                        nameFunction: "",
                        startDate: "",
                        endDate: "",
                        nameResponsible: "",
                        integratorName: "",
                        responsibleEmail: "",
                        companyPhone: "",
                        accessLevel: null,
                        typeAttendanceId: "277",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "4328",                 
                    }
                },

                {
                    typeAttendanceId: "P8", /* PROTOCOLO / OUTRAS SOLICITAÇÕES */
                    title: 'Outras Solicitações',
                    modality: [1,2,3,4,5],
                    concluded: ["S","N"],
                    data: {                          
                        respAttendantId: "", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00018",                       
                        subject: "OUTRAS SOLICITAÇÕES - PORTAL DO ALUNO",
                        accessLevel: null,
                        typeAttendanceId: "185",
                        groupAttendanceId: "17",                        
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "4328",
                    }
                },

                {
                    typeAttendanceId: "P9", /* PROTOCOLO / TRANSFERÊNCIA DE FILIAL/POLO */
                    title: 'Transferência de Filial/Polo',
                    modality: [1,2,3,4,5],
                    concluded: ["N"],
                    data: {                          
                        respAttendantId: "", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00044",                       
                        subject: "TRANSFERÊNCIA DE FILIAL/POLO - PORTAL DO ALUNO",
                        nameCourse: "",
                        accessLevel: null,
                        typeAttendanceId: "190",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "4328",                      
                    }
                },

                {
                    typeAttendanceId: "P10", /* PROTOCOLO / TRANSFERÊNCIA DE TURNO E TURMA */
                    title: 'Transferência de Turno e Turma',
                    modality: [1,2],
                    concluded: ["N"],
                    data: {                          
                        respAttendantId: "1933", // NÃO RESPEITA - VIA RM
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00044",                       
                        subject: "TRANSFERÊNCIA DE TURNO E TURMA - PORTAL DO ALUNO",
                        nameCourse: "",
                        accessLevel: null,
                        typeAttendanceId: "191",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "4328",                      
                    }
                },

                {
                    typeAttendanceId: "P11", /* PROTOCOLO / NEGOCIAÇÃO DE PARCELAS EM ATRASO */
                    title: 'Negociação de Parcelas em Atraso',
                    modality: [1,2,3,4,5],
                    concluded: ["S","N"],
                    data: {                          
                        respAttendantId: "4545", // NÃO RESPEITA - VIA RM
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00019",                       
                        subject: "NEGOCIAÇÃO DE PARCELAS EM ATRASO - PORTAL DO ALUNO",
                        nameCourse: "",
                        accessLevel: null,
                        typeAttendanceId: "192",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "4328",                      
                    }
                },

                {
                    typeAttendanceId: "P12", /* PROTOCOLO / TRANCAMENTO GRADUAÇÃO */
                    title: 'Trancamento',
                    modality: [3],
                    concluded: ["N"],
                    data: {                          
                        respAttendantId: "4288", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00057",                       
                        subject: "TRANCAMENTO GRADUAÇÃO - PORTAL DO ALUNO",
                        nameCourse: "",
                        accessLevel: null,
                        typeAttendanceId: "193",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "4328",                      
                    }
                },

                {
                    typeAttendanceId: "P13", /* PROTOCOLO / TRANCAMENTO CURSO LIVRE */
                    title: 'Trancamento',
                    modality: [2],
                    concluded: ["N"],
                    data: {                          
                        respAttendantId: "1933", // NÃO RESPEITA - VIA RM
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00057",                       
                        subject: "TRANCAMENTO CURSO LIVRE - PORTAL DO ALUNO",
                        nameCourse: "",
                        accessLevel: null,
                        typeAttendanceId: "194",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "4328",                
                    }
                },

                {
                    typeAttendanceId: "P14", /* PROTOCOLO / TROCA DE CURSO */
                    title: 'Troca de Curso',
                    modality: [1,2,3,4,5],
                    concluded: ["N"],
                    data: {                          
                        respAttendantId: "3282", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00043",                       
                        subject: "TROCA DE CURSO - PORTAL DO ALUNO",
                        nameCourse: "",
                        accessLevel: null,
                        typeAttendanceId: "121",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "4328",                
                    }
                },

                {
                    typeAttendanceId: "P15", /* PROTOCOLO / FORMATURA CURSO LIVRE */
                    title: 'Formatura',
                    modality: [2],
                    concluded: ["S","N"],
                    data: {                          
                        respAttendantId: "5125", // NÃO RESPEITA - VIA RM
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00028",                       
                        subject: "FORMATURA CURSO LIVRE - PORTAL DO ALUNO",
                        nameCourse: "",
                        accessLevel: null,
                        typeAttendanceId: "197",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "4328",          
                    }
                },

                {
                    typeAttendanceId: "P16", /* PROTOCOLO / FORMATURA CURSO TÉCNICO */
                    title: 'Formatura',
                    modality: [1],
                    concluded: ["S","N"],
                    data: {                          
                        respAttendantId: "5125", // NÃO RESPEITA - VIA RM
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00028",                       
                        subject: "FORMATURA CURSO TÉCNICO - PORTAL DO ALUNO",
                        nameCourse: "",
                        accessLevel: null,
                        typeAttendanceId: "200",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "4328",    
                    }
                },

                {
                    typeAttendanceId: "P17", /* PROTOCOLO / SOLICITAÇÕES NADD */
                    title: 'Solicitações NADD',
                    modality: [3,5],
                    concluded: ["N"],
                    data: {                          
                        respAttendantId: "5275", // NÃO RESPEITA - VIA RM
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00090",                       
                        subject: "SOLICITAÇÕES NADD - PORTAL DO ALUNO",
                        nameCourse: "",
                        accessLevel: null,
                        typeAttendanceId: "208",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "4328",          
                    }
                },
                
                {
                    typeAttendanceId: "P18", /* PROTOCOLO / APROVEITAMENTO DE ESTUDOS */
                    title: 'Aproveitamento de Estudos',
                    modality: [3,5],
                    concluded: ["N"],
                    data: {                          
                        respAttendantId: "4288", // NÃO RESPEITA - VIA RM
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00138",                       
                        subject: "APROVEITAMENTO DE ESTUDOS - PORTAL DO ALUNO",
                        nameCourse: "",
                        attachmentHistoric: "",
                        fileNameHistoric: "",
                        attachmentSummary: "",
                        fileNameSummary: "",
                        accessLevel: null,
                        typeAttendanceId: "264",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "4328",          
                    }
                },

                {
                    typeAttendanceId: "P19", /* PROTOCOLO / VERIFICAÇÃO FINANCEIRA */
                    title: 'Verificação Financeira',
                    modality: ['-'], // AJUSTAR
                    concluded: ['-'], // AJUSTAR
                    data: {                          
                        respAttendantId: "-", // AJUSTAR
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00139",                       
                        subject: "VERIFICAÇÃO FINANCEIRA - PORTAL DO ALUNO",
                        accessLevel: null,
                        typeAttendanceId: "265", // AJUSTAR FLUXO
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "4328",          
                    }
                },

                {
                    typeAttendanceId: "P20", /* PROTOCOLO / PRAZO DE PROVAS E ATIVIDADES (MOODLE) */
                    title: 'Prazo de Provas e Atividades (Moodle)',
                    modality: ['-'], // AJUSTAR
                    concluded: ['-'], // AJUSTAR
                    data: {                          
                        respAttendantId: "-", // AJUSTAR
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00140",                       
                        subject: "PRAZO DE PROVAS E ATIVIDADES - PORTAL DO ALUNO",
                        accessLevel: null,
                        typeAttendanceId: "266", // AJUSTAR FLUXO
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "4328",          
                    }
                },

                {
                    typeAttendanceId: "P21", /* PROTOCOLO / EMENTA */
                    title: 'Ementa',
                    modality: ['-'], // AJUSTAR
                    concluded: ['-'], // AJUSTAR
                    data: {                          
                        respAttendantId: "4330", // AJUSTAR
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00141",                       
                        subject: "EMENTA - PORTAL DO ALUNO",
                        accessLevel: null,
                        typeAttendanceId: "260", // AJUSTAR FLUXO
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "4328",          
                    }
                },

                {
                    typeAttendanceId: "P22", /* PROTOCOLO / VERIFICAÇÃO DE NOTAS */
                    title: 'Verificação de Notas',
                    modality: ['-'], // AJUSTAR
                    concluded: ['-'], // AJUSTAR
                    /*
                    modality: [3,5],
                    concluded: ["N"],
                    */
                    data: {                          
                        respAttendantId: "4288",
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00142",                       
                        subject: "VERIFICAÇÃO DE NOTAS - PORTAL DO ALUNO",
                        accessLevel: null,
                        typeAttendanceId: "267", // AJUSTAR FLUXO
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "4328",          
                    }
                },

                {
                    typeAttendanceId: "P23", /* PROTOCOLO / TUTORIA DE DISCIPLINA */
                    title: 'Tutoria de Disciplina',
                    modality: ['-'], // AJUSTAR
                    concluded: ['-'], // AJUSTAR
                    data: {                          
                        respAttendantId: "-", // AJUSTAR
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00143",                       
                        subject: "TUTORIA DE DISCIPLINA - PORTAL DO ALUNO",
                        accessLevel: null,
                        typeAttendanceId: "268", // AJUSTAR FLUXO
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "4328",          
                    }
                },

                {
                    typeAttendanceId: "P24", /* PROTOCOLO / ATIVIDADE COMPLEMENTAR */
                    title: 'Atividade Complementar',
                    modality: [3],
                    concluded: ["S","N"],
                    data: {                          
                        respAttendantId: "4288",
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00144",                       
                        subject: "ATIVIDADE COMPLEMENTAR - PORTAL DO ALUNO",
                        attachmentCertificadoComplementar: "",
                        fileNameCertificadoComplementar: "",
                        accessLevel: null,
                        typeAttendanceId: "269",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "4328",          
                    }
                },

                {
                    typeAttendanceId: "P25", /* PROTOCOLO / DIPLOMA (2ª VIA) */
                    title: 'Diploma (2ª via)',
                    modality: [3,5],
                    concluded: ["S","N"],
                    data: {                          
                        respAttendantId: "4330", // NÃO RESPEITA - VIA RM
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00137",                      
                        subject: "DIPLOMA (2ª VIA) - PORTAL DO ALUNO",
                        nameCourse: "",
                        accessLevel: null,
                        typeAttendanceId: "237",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "4328",            
                    }
                },

                {
                    typeAttendanceId: "P26", /* PROTOCOLO / AGENDAMENTO DE EXAME */
                    title: 'Agendamento de Exame',
                    modality: [3],
                    concluded: ["N"],
                    data: {                          
                        respAttendantId: "4288", // NÃO RESPEITA - VIA RM
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00127",                     
                        subject: "AGENDAMENTO DE EXAME - PORTAL DO ALUNO",
                        nameCourse: "",
                        accessLevel: null,
                        typeAttendanceId: "239",
                        groupAttendanceId: "17",
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "4328",          
                    }
                },

                {
                    typeAttendanceId: "P27", /* PROTOCOLO / REMATRÍCULA */
                    title: 'Rematrícula',
                    modality: [3],
                    concluded: ["N"],
                    data: {                          
                        respAttendantId: "4288", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00021",                       
                        subject: "REMATRÍCULA - PORTAL DO ALUNO",
                        nameCourse: "",
                        accessLevel: null,
                        typeAttendanceId: "263",
                        groupAttendanceId: "17",                        
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "4328",
                    }
                },

                {
                    typeAttendanceId: "P28", /* PROTOCOLO / SOLICITAÇÕES NETEP */
                    title: 'NETeP - Suporte ao Ambiente de Aprendizagem (Moodle)',
                    modality: [1,2,4],
                    concluded: ["S", "N"],
                    data: {                          
                        respAttendantId: "5798", 
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00126",                       
                        subject: "SOLICITAÇÕES NETEP - PORTAL DO ALUNO",
                        nameCourse: "",
                        accessLevel: null,
                        typeAttendanceId: "240",
                        groupAttendanceId: "17",                        
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "4328",
                    }
                },

                {
                    typeAttendanceId: "P29", /* PROTOCOLO / ALTERAÇÃO DE DADOS */
                    title: 'Alteração de Dados',
                    modality: ['-'], // AJUSTAR
                    concluded: ['-'], // AJUSTAR
                    /*
                    modality: [1,2,3,4,5],
                    concluded: ["N"],
                    */
                    data: {                          
                        respAttendantId: "1933",
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00018",                      
                        subject: "ALTERAÇÃO DE DADOS - PORTAL DO ALUNO",
                        nameCourse: "",
                        accessLevel: null,
                        typeAttendanceId: "270", // AJUSTAR FLUXO
                        groupAttendanceId: "17",                        
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "4328",
                    }
                },

                {
                    typeAttendanceId: "P30", /* PROTOCOLO / ALTERAÇÃO DE FOTO */
                    title: 'Alteração de Foto',
                    modality: ['-'], // AJUSTAR
                    concluded: ['-'], // AJUSTAR
                    /*
                    modality: [1,2,3,4,5],
                    concluded: ["N"],
                    */
                    data: {                          
                        respAttendantId: "1933",
                        productId: "10",
                        locationId: "",
                        colligateId: "1",
                        respColligateId: "1",
                        classificationId: "00018",                      
                        subject: "ALTERAÇÃO DE FOTO - PORTAL DO ALUNO",
                        attachment: "",
                        fileName: "Foto de Perfil",
                        nameCourse: "",
                        accessLevel: null,
                        typeAttendanceId: "271", // AJUSTAR FLUXO
                        groupAttendanceId: "17",                        
                        ra: "",
                        requestText: "",
                        clientType: "Aluno",
                        attendantId: "4328",
                    }
                },
            ]    
    }

    getDataByAttendanceId(typeAttendanceId){
        for (let i=0; i<this.instance.length; i++){
            if(this.instance[i].typeAttendanceId == typeAttendanceId)
                return this.instance[i];
        }
    }

    getProtocolByRules(modality, concluded){
        const protocol = [];
        for (let i=0; i<this.instance.length; i++){
            if(this.instance[i].modality.find(element => element == modality) && this.instance[i].concluded.find(element => element == concluded))
                protocol.push(this.instance[i])
        }
        return protocol;
    }

}